
import { Component, Vue } from "vue-property-decorator";
import { EmployerNoticeService } from "src/services";
import { Toast, Dialog } from "vant";
import tabbar from "src/components/tabbar";

@Component({
    components:
    {
        tabbar
    }
})
export default class Notice extends Vue
{
    /**
     * 消息列表数据
     * @private
     * @returns Array<any>
     */
    protected noticeList: Array<any> =[];

    /**
     * 获取订单列表 
     */
    protected async loadData(parameter: any): Promise<any> {
        try {
            let { content: res } = await EmployerNoticeService.instance.noticePageList(Object.assign({noticeMode: 1}, parameter));
            return res.data;
        } catch (err) {
            Toast(err);
        }
    }


    /**
     * 跳转详情
     * @private
     * @returns void
     */
    private onDetail(id: string): void
    {
       this.$router.push({name: "notice-detail", query:{id:id}})
    }
}
